@charset "UTF-8";
@use "settings" as s;

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1680;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

$color: #01739d;


//font

html,body{
	font-family: dnp-shuei-gothic-gin-std,sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 62.5%;
}
body{
	background: #F7F7F7;
}



//utility


// .v{
// 	writing-mode: vertical-rl;
// 	text-orientation: upright;
// }
.note{
	font-size: 1.4rem;
	line-height: calc(24 / 14);
	margin-top: 10px;
}
.br10{
	border-radius: 10px;
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
}
.br8{
	border-radius: 8px;
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
}

//component

.section{
	&__head{
		color: $color;
		font-size: 2.4rem;
		line-height: 1.4;
		text-align: left;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		&::after{
			display: block;
			margin-top: 7px;
			content: '';
			width: 3em;
			height: 3px;
			background: $color;
		}
		&--no{
			&::after{
				display: none;
			}
		}
	}
}

.contents{
	&__head{
		font-size: 3.4rem;
		font-weight: 600;
		color: $color;
		writing-mode: vertical-rl;
		text-orientation: upright;
		&::before{
			display: block;
			content: '';
			width: 4px;
			height: 2.2em;
			margin-left: 5px;
			background: $color;
		}
		@include s.mq(w767){
			font-size: 2rem;
		}
	}
}

.btn{
	// width: 140px;
	max-width: 215px;
	height: 44px;
	padding: 14px 20px;
	border-radius: 10px;
	box-shadow: 0 3px 0 0 #005472;
	background: $color;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	font-weight: 600;
	transition: all .2s;
	&::after{
		display: block;
		content: '';
		width: 16px;
		height: 16px;
		background: url(../img/common/ico-btn-arrow.svg) no-repeat;
		background-size: 100%;
		margin-left: 3px;
	}
	&:hover{
		background: #1b87ae;
	}
}

.hours{
	width: 100%;
	th,td{
		font-size: 1.8rem;
		font-weight: 600;
		text-align: center;
		padding: 17px 10px;
		border: 1px solid #eaeaea;
		vertical-align: middle;
		line-height: 1.4;
	}
	th{
		background: $color;
		color: #fff;
		width: 8em;
	}
	td{
		color: $color;
		background: #fff;
	}
	.day{
		width: 93px;
	}
	&__note{
		font-weight: 700;
		color: #d10000;
	}
}

.table{
	font-size: 1.6rem;
	line-height: 1.7;
	th,td{
		padding: 14px 0;
		@include s.mq(w767){
			width: 100%;
			display: block;
		}
	}
	th{
		color: $color;
		border-bottom: 1px solid $color;
		white-space: nowrap;
		padding-right: 1em;
		width: 10em;
		@include s.mq(w767){
			width: 100%;
			padding: 0 0 5px 0;
		}
	}
	td{
		border-bottom: 1px solid $color;
		@include s.mq(w767){
			border: none;
			padding-bottom: 16px;
		}
	}
	h5{
        color: $color;
        margin: 16px 0 5px;
		font-weight: 600;
    }
	&--list{
		@include s.mq(w767){
			th,td{
				display: block;
			}
			th{
				width: 100%;
			}
			td{
				border: none;
			}
		}
	}
}

//page

.page{
	.kv{
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 540px;
		display: flex;
		justify-content: center;
		align-items: center;
		&__catch{
			font-size: 5.4rem;
			font-size: clamp(3rem,3vw, 5.4rem);
			font-weight: 600;
			letter-spacing: .05em;
			color: #fff;
			text-align: center;
		}
	}
	&__wrapper{
		width: 100%;
		max-width: 1210px;
		padding: vw(80) 20px 160px;
		margin: auto;
		@include s.mq(w767){
			padding-top: vw(120);
		}
	}
	.section + .section{
		margin-top: 80px;
	}
	.section{
		&__head{
			margin-bottom: 20px;
		}
		&__subhead{
			font-size: 2rem;
			font-weight: 600;
			line-height: 1.7;
			margin-bottom: 4px;
			color: $color;
		}
		&__txt{
			font-size: 1.6rem;
			line-height: calc(29 / 16);
		}
		&__contents{
			margin-top: 20px;
			.fw{
				margin-bottom: 30px;
			}
			.table{
				margin-top: 40px;
			}
			.section__subhead{
				margin-top: 50px;
			}
		}
		&__kv{
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 412px;
			// font-size: 3.6rem;
			font-size: clamp(2.4rem, 3vw, 3.6rem);
			font-weight: 600;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: calc(50% - 50vw);
			margin-left: calc(50% - 50vw);
			margin-bottom: 50px;
			line-height: 1.4;
			text-align: center;
			padding:0 20px;
		}
	}
	.section__txt + .section__txt{
		margin-top: .8em;
	}
	.ul{
		padding-left: 2em;
		&__item{
			list-style-type: disc;
			font-size: 1.6rem;
			line-height: calc(29 / 16);
		}
	}
	.contents{
		&__head{
			writing-mode:initial;
			text-orientation:initial;
			margin-bottom: 40px;
			color: $color;
			text-align: left;
			line-height: 1.4;
			&::before{
				display: none;
			}
			&::after{
				display: block;
				content: '';
				width:  2.2em;
				height: 4px;
				margin-top: 9px;
				background: $color;
			}
			&--st{
				margin-bottom: 16px;
				font-size: 2rem;
				font-weight: 600;
				line-height: calc(35 / 20);
				color: $color;
				&::after{
					display: none !important;
				}
			}
		}
		&__lead{
			font-size: 1.6rem;
			line-height: calc(29 / 16);
		}
	}
	.horizontal{
		display: flex;
		flex-direction: row-reverse;
		gap: 30px;
		@include s.mq(w767){
			gap: 30px;
			flex-direction: column-reverse;
		}
		&__txt{
			width: 670px;
			@include s.mq(w767){
				width: 100%;
			}
			&--large{
				width: 470px;
				@include s.mq(w767){
					width: 100%;
				}
			}
		}
		&__img{
			width: 470px;
			@include s.mq(w767){
				width: 100%;
			}
			&--large{
				width: 670px;
				@include s.mq(w767){
					width: 100%;
				}
			}
		}
	}
}

//header
.header{
	display: flex;
	align-items: center;
	gap: 30px;
	padding: 30px 20px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	@include s.mq(w767){
		padding: 20px 10px 0;
	}
	&__logo{
		width: 312px;
		height: auto;
		@include s.mq(w767){
			width: 280px;
		}
	}
}
.gnav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	width: 100%;
	@include s.mq(w1380){
		display: none;
	}
	&__list{
		display: flex;
		gap: 30px;
		@include s.mq(w1500){
			gap: 15px;
		}
	}
	&__link{
		font-size: 1.6rem;
		color: #fff;
		text-align: left;
		@include s.mq(w1500){
			font-size: 1.4rem;
		}
	}
	&__utility{
		display: flex;
		gap: 25px;
		&__link{
			font-size: 1.4rem;
			color: #fff;
		}
	}
	&__utility__link,&__link{
		display: flex;
		flex-direction: column;
		align-items: center;
		&::after{
			display: block;
			content: '';
			width: 0;
			height: 1px;
			background: #fff;
			transition: all .1s;
			margin-top: 5px;
		}
		&:hover{
			&::after{
				display: block;
				content: '';
				width: 4em;
				height: 1px;
				background: #fff;
			}
		}
	}
}

//menu

.menu {
	position: absolute;
	top: 33px;
	right: 20px;
    width: 48px;
    //height: 13px;
    //position: relative;
    display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
    padding: 0;
    border: 0;
	color: #fff;
	background:  transparent;
	font-family: new-frank, sans-serif;
	font-weight: 400;
	font-size: 1.2rem;
	letter-spacing: -0.56px;
	z-index: 1;
	@include s.mq(w767){
		top: 27px;
		right: 10px;
	}
	&__text{
		margin-top: 5px;
		line-height: 1;
	}
	&__btn{
		width: 48px;
		height: 21px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	&__border{
		width:48px;
		height:1px;
		// left: 0;
		// right: 0;
		transform: translateY(-50%);
		background-color:#fff;
		// position:absolute;
		transition: all .4s ease;
		// &:nth-child(2){
		// 	top:15px;
		// }
		// &:last-child{
		// 	bottom:15px;
		// }
	}
}
// メニュー表示中
.menu-active{
	overflow: hidden;
	.menu{
		color: #000;
		&__border{
			background: #000;
			&:first-child {
				transform: translateY(10px) rotate(-16deg);
			}
			&:nth-child(2){
				display: none;
			}
			&:last-child {
				transform: translateY(-10px) rotate(16deg);
			}
		}
	}
}

//drawer
.drawer{
	display: none;
	width: 100%;
	height: 100%;
	margin: auto;
	padding: 20px;
	background: #eff1f2;
	position: fixed;
	top: 0;
	left: 0;
	&__logo{
		width: 369px;
		@include s.mq(w767){
			width: 260px;
		}
	}
	&__inner{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__wrapper{
		width: 100%;
		max-width: 855px;
	}
	&__item + &__item{
		border-top: 1px solid #01739d;
	}
	&__link{
		font-size: 2.4rem;
		font-size: clamp(1.6rem,1vw, 2.4rem);
		font-weight: 600;
		color: #01739d;
		display: block;
		width: 100%;
		line-height: 1;
		padding: clamp(1.4rem,1vw, 2.6rem) 0;
	}
	.contact{
		&-nav{
			&__list{
				margin-top: vw(70);
				@include s.mq(w767){
					gap: 15px;
				}
			}
			&__link{
				@include s.mq(w767){
					height: 65px;
				}
			}
		}
	}
}

//footer
.contact{
	background: #fff;
	padding: 70px 20px;
	&__inner{
		width: 100%;
		max-width: 1383px;
		margin: auto;
		display: flex;
		align-items: center;
		gap: 39px;
		padding: 0 20px;
		@include s.mq(w980){
			width: 100%;
			flex-direction: column;
		}
	}
	&__outline{
		width: 449px;
		flex-shrink: 0;
		@include s.mq(w1140){
			width: 100%;
			max-width: 449px;
			flex-shrink: 2;
		}
		@include s.mq(w980){
			width: 100%;
			max-width: 100%;
		}
	}
	// &__head{
	// 	font-size: 2.4rem;
	// }
	&__txt{
		font-size: 1.6rem;
		font-weight: 600;
		line-height: calc(27 / 16);
		margin-top: 12px;
	}
	&__note{
		font-size: 1.2rem;
		line-height: calc(20 / 12);
		margin-top: 5px;
	}
	&-nav{
		//width: 855px;
		width: 100%;
		// max-width: 855px;
		&__list{
			display: flex;
			justify-content: space-between;
			gap: 31px;;
			@include s.mq(w767){
				flex-direction: column;
			}
		}
		&__item{
			width: 50%;
			@include s.mq(w767){
				width: 100%;
			}
		}
		&__link{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 412px;
			width: 100%;
			// width: vw(412);
			height: 85px;
			border-radius: 10px;
			box-shadow: 0 3px 0 0 #005472;
			background: $color;
			color: #fff;
			font-weight: 600;
			&:hover{
				opacity: .7;
			}
			&--mail{
				font-size: 2.8rem;
				
				@include s.mq(w1342){
					font-size: 2rem;
				}
				&::before{
					display: inline-block;
					content: '';
					background: url(../img/common/ico-mail.svg) no-repeat;
					background-size: 100%;
					width: 34px;
					height: 23px;
					margin-right: 5px;
					@include s.mq(w1342){
						width:24px;
						height: 16px;
					}
				}
			}
			&--tel{
				display: flex;
				flex-direction: column;
				font-size: 1.7rem;
				@include s.mq(w1342){
					font-size: 1.4rem;
				}
			}
			&--num{
				font-size: 3rem;
				margin-top: 5px;
				display: block;
				&::before{
					display: inline-block;
					content: '';
					background: url(../img/common/ico-tel.svg) no-repeat;
					background-size: 100%;
					width: 26px;
					height: 26px;
					margin-right: 5px;
					@include s.mq(w1342){
						width: 20px;
						height: 20px;
					}
				}
				@include s.mq(w1342){
					font-size: 2.4rem;
				}
			}
		}
	}

}

.footer{
	&-service{
		background: #e1edf2;
		padding: 20px 28px;
		&__list{
			width: 100%;
			max-width: 1343px;
			margin: auto;
			display: grid;
			gap: 30px;
			grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		}
		&__item{
			width: 100%;
		}
		&__link{
			display: block;
			background: #fff;
			border-radius: 10px;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, .08);
			transition: all .2s;
			&:hover{
				background: #1b87ae;
				color: #fff;
			}
		}
		&__img{
			border-radius: 10px 10px 0 0;
		}
		&__head{
			height: 33px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.6rem;
			font-weight: 600;
		}
	}
	&__logo{
		width: 233px;
		height: auto;
		margin: auto;
	}
	&-info{
		text-align: center;
		background: $color;
		font-weight: 500;
		color: #fff;
		padding: 56px 20px 60px;
	}
	&-gnav{
		margin-top: 30px;
		&__list{
			display: flex;
			justify-content: center;
			gap: 27px;
			@include s.mq(w1280){
				flex-wrap: wrap;
				gap: 18px;
			}
		}
		&__link{
			font-size: 1.6rem;
			color: #fff;
		}
	}
	&__address{
		font-size: 1.4rem;
		line-height: calc(29 / 14);
		margin-top: 50px;
	}
	&__copyright{
		font-size: 1.2rem;
		text-transform: uppercase;
		margin-top: 34px;
		display: block;
	}
}