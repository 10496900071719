//$path-img: '../img';
/*! purgecss start ignore */

// max-width
$breakpoint-down: (
  'w390': 'screen and (max-width: 390px)',
  'w399': 'screen and (max-width: 399px)',
  'w420': 'screen and (max-width: 420px)',
  'w587': 'screen and (max-width: 587px)',
  'w670': 'screen and (max-width: 670px)',
  'w767': 'screen and (max-width: 767px)',
  'w850': 'screen and (max-width: 850px)',
  'w900': 'screen and (max-width: 900px)',
  'w980': 'screen and (max-width: 980px)',
  'w1024': 'screen and (max-width: 1024px)',
  'w1040': 'screen and (max-width: 1040px)',
  'w1080': 'screen and (max-width: 1080px)',
  'w1140': 'screen and (max-width: 1140px)',
  'w1200': 'screen and (max-width: 1200px)',
  'w1240': 'screen and (max-width: 1240px)',
  'w1280': 'screen and (max-width: 1280px)',
  'w1342': 'screen and (max-width: 1342px)',
  'w1380': 'screen and (max-width: 1380px)',
  'w1440': 'screen and (max-width: 1440px)',
  'w1500': 'screen and (max-width: 1500px)',
  'w1550': 'screen and (max-width: 1550px)',
  'w1600': 'screen and (max-width: 1600px)',
  'w1750': 'screen and (max-width: 1750px)',
) !default;

// @mixinの定義

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

/*! purgecss end ignore */