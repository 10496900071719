@charset "UTF-8";
@use "reset";
@use "settings" as s;
@use "user_reset";
@use "common";
@use "slide";
@use "scroll-hint";

@function clampVw($min, $size, $max, $vp: 1920) {
	$min: $min * 0.1rem;
	$max: $max * 0.1rem;
	$num: 100 / $vp * 1vw;
  	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

@function vw ($num){
    $viewport: 1920;
    $mathVw: $num / $viewport * 100vw;
    @return round( $mathVw * 1000) / 1000;
}

$color: #01739d;


//スライダー
.splide{
	// &__arrows{
	// 	position: absolute;
	// 	width: 100%;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	display: flex;
	// 	justify-content: space-between;
	// 	padding: 0 10px;
	// 	@include s.mq(w767){
	// 		justify-content: flex-end;
	// 	}
	// }
	// &__arrow{
	// 	-webkit-appearance:none;
	// 	border: none;
	// 	background: transparent;
	// 	cursor: pointer;
	// 	transition: all .2s;
	// 	width: 90px;
	// 	height: 90px;
	// 	@include s.mq(w767){
	// 		width: 50px;
	// 		height: 50px;
	// 		&--prev{
	// 			display: none;
	// 		}
	// 	}
	// 	padding: 0;
	// 	&:hover{
	// 		opacity: .7;
	// 	}
	// }
}


//common



.kv{
	background: url(../img/top/kv@2x.jpg) no-repeat center center/cover;
	height: 100svh;
	
	&__slide{
		width: 100%;
		height: 100svh;
		//object-fit: cover;
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__img{
		width: 100%;
		max-width: 1170px;
	}
	// .splide{
	// 	z-index: -1;
	// }
	&__slide{
		&--catch{
			position: relative;
		}
	}
	&__catch{
		font-size: 5.4rem;
		font-size: clamp(2rem,3vw, 5.4rem);
		font-weight: 600;
		letter-spacing: .05em;
		color: #fff;
		writing-mode: vertical-rl;
		text-orientation: upright;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.news{
		position: absolute;
		left: 0;
		bottom: 0;
		background: $color;
		width: 808px;
  		height: 53px;
		color: #fff;
		font-size: 1.4rem;
		border-radius: 0 10px 0 0;
		display: flex;
		align-items: center;
		@include s.mq(w850){
			width: 90%;
		}
		&__head{
			width: 105px;
			height: 53px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #004c69;
		}
		&__list{
			display: flex;
			padding: 0 22px;
		}
		&__date{
			margin-right: 10px;
		}
		&__link{
			color: #fff;
		}
	}
}


//about

.about{
	width: 100%;
	max-width: 1610px;
	padding: 0 20px;
	position: relative;
	margin: vw(120) auto 0;
	@include s.mq(w1750){
		max-width: vw(1600);
	}
	@include s.mq(w767){
		max-width: 100%;
		padding: 0;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
	}
	&__head{
		position: absolute;
		top: -40px;
		left: -60px;
		@include s.mq(w850){
			top: 0;
			left: -6vw;
		}
		@include s.mq(w767){
			position: static;
			margin: 0 auto 30px auto;
		}
	}
	.splide.is-initialized:not(.is-active) .splide__list{
		display: grid;
	// }
	// &__list{
		width: 100%;
		margin: auto;
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		@include s.mq(w1600){
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			// flex-wrap: wrap;
		}
		@include s.mq(w850){
			grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		}
	}
	&__link{
		border-radius: 10px;
		box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
		background-color: #fff;
		display: flex;
		flex-direction: column;
		height: 331px;
		height: 100%;
		padding-bottom: 16px;
		&:hover{
			opacity: 0.7;
		}
	}
	&__img{
		order: -1;
		border-radius: 10px 10px 0 0;
	}
	&__title{
		font-size: 1.8rem;
		line-height: 1.4;
		font-weight: 600;
		color: $color;
		text-align: center;
		margin-top: 18px;
	}
	&__txt{
		padding: 0 18px;
		font-size: 1.4rem;
		line-height: calc(24 / 14);
		margin-top: 14px;
		color: #000;
	}
}

//philosophy
.philosophy{
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	gap: vw(104);
	margin-top: vw(183);
	@include s.mq(w1240){
		gap: vw(70);
	}
	@include s.mq(w767){
		flex-direction: column-reverse;
	}
	// &__head{
	// 	writing-mode: horizontal-tb;
	// 	&::before{

	// 	}
	// }
	&__contents{
		width: vw(411);
		display: flex;
		flex-direction: column;
		align-items: center;
		@include s.mq(w1240){
			width: vw(800);
		}
		@include s.mq(w767){
			width: 90%;
			margin: auto;
		}
	}
	&__body{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include s.mq(w767){
			flex-direction: row;
			align-items: flex-start;
			gap: 30px;
		}
	}
	&__txt{
		font-size: 1.6rem;
		line-height: calc(29 / 16);
		margin-top: vw(75);
	}
	&__img{
		width: vw(1253);
		height: vw(688);
		@include s.mq(w1240){
			width: vw(1000);
			height: auto;
		}
		@include s.mq(w767){
			width: vw(1800);
		}
		img{
			border-radius: 0px 10px 10px 0px;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
			@include s.mq(w1240){
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.btn{
		margin-top: 29px;
	}
}

//initiative
.initiative{
	width: 100%;
	max-width: 1422px;
	padding: 0 20px;
	margin: vw(171) auto 0;
	position: relative;
	@include s.mq(w767){
		margin-top: vw(300);
	}
	&__head{
		position: absolute;
		top: 0;
		left: -65px;
		@include s.mq(w1600){
			position: static;
		}
		@include s.mq(w767){
			margin: 0 auto 30px auto;
		}
	}
	&__list{
		display: grid;
		gap: 52px;
		grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
		@include s.mq(w767){
			display: block;
		}
	}
	&__item{
		display: flex;
		flex-direction: column-reverse;
		@include s.mq(w767){
			&:not(:first-child){
				margin-top: 40px;
			}
		}
	}
	&__contents{
		display: flex;
		justify-content: space-between;
		margin-top: 38px;
		@include s.mq(w767){
			flex-direction: column;
			gap: 20px;
			margin-top: 20px;
		}
	}
	&__title{
		font-size: 1.8rem;
		font-weight: 600;
		color: $color;
	}
	&__txt{
		font-size: 1.6rem;
		line-height: 1.7;
		margin-top: 10px;
	}
	&__img{
		img{
			border-radius: 10px;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
		}
	}
}

//access
.access{
	background: #eff1f2;
	padding: 50px 20px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	gap: 70px;
	margin-top: vw(160);
	@include s.mq(w1024){
		flex-direction: column;
	}
	&__head{
		width: 338px;
		height: auto;
	}
	&__address{
		font-size: 1.6rem;
		line-height: calc(29 / 16);
		margin-top: 18px;
	}
	&__map{
		width: 100%;
		max-width: vw(890);
		position: relative;
		@include s.mq(w1024){
			max-width: 100%;
		}
	}
	iframe{
		width: 100%;
		aspect-ratio: 16/9; 
	}
	&__btn{
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}
	.hours{
		margin-top: 20px;
	}
	// .note{
	// 	margin-top: 5px;
	// }
}